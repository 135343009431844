.contact-form {
  max-width: 360px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  padding: 16px;
}

.contact-form > :not(:last-child) {
  margin-bottom: 16px;
}

.contact-form .form-field {
  display: flex;
  flex-direction: column;
}

.contact-form .label-content {
  margin-bottom: 4px;
}

.contact-form input, .contact-form textarea {
  display: flex;
  flex: 1;
  width: 100%;
  max-width: 100%;
}

.contact-form label {
  text-align: left;
}

/* Send Button */
.send-button {
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.send-button:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .send-button {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .send-button {
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
  }
}
