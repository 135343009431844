@import url('https://fonts.googleapis.com/css?family=Berkshire+Swash');
@import url('https://fonts.googleapis.com/css?family=Montserrat:700');
@import url('https://fonts.googleapis.com/css?family=Roboto');
@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('shared/fonts/HelveticaNeue-Condensed.otf');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url('shared/fonts/HelveticaNeue-CondensedBold.otf');
  font-weight: bold;
  font-style: normal;
}

body {
  background-color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

/* TODO - Top right corner quote button */
.quote-button {
  position: absolute;
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.quote-button:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .quote-button {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .quote-button {
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
  }
}
