/* Not working after router change */
.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

body {
  margin: 0;
  padding: 0;
  --primary-color: #960018;
  --primary-dark: #610301;
  --secondary-color: #444;
  --secondary-light: #555;
  --secondary-dark: #2e2f33;
  --secondary-dark-opaque: rgba(46, 47, 51, 0.9);
  --accent-color: #ccc;
  --accent-light: #eee;
}

@media (max-width: 410px) {
  h1 {
    font-size: 32px;
  }
}

#loading {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #2e2f33;
  z-index: 1099;
}

#loading-image {
  position: absolute;
  background-image: url('../shared/loading_gear.png');
  background-size: cover;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  -webkit-animation: spin 5s linear infinite, fadeIn 0.5s ease-in;
  animation: spin 5s linear infinite, fadeIn 0.5s ease-in;
  z-index: 1100;
}
@media (max-width: 768px) {
  #loading-image {
    height: 150px;
    width: 150px;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
  }
}

.picture {
  width: auto;
  height: auto;
}

.element {
  padding: 100px 0;
}

.title {
  width: 55%;
  font-weight: 700;
  font-size: 50px;
  color: var(--primary-color);
  margin: auto;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 4px solid;
}
@media (max-width: 768px) {
  .title {
    width: 80%;
  }
}

.header {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 992px) {
  .col-md-5th {
    width: 20%;
  }
}

.logo {
  height: 50px;
  width: 50px;
}
@media (max-width: 768px) {
  .logo {
    height: 40px;
    width: 40px;
  }
}

#title {
  margin: 0;
  line-height: 300%;
  background-color: black;
  color: white;
  border: none;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

.max-size {
  width: 100%;
  height: 100%;
}

.bob {
  font-size: 130%;
  line-height: 300%;
  vertical-align: middle;
  display: inline-block;
}

#fam-pic {
  height: 80%;
  width: 100%;
}

/* Used for list in the footer TODO: assign names */
li {
  padding-bottom: 0px;
}

ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #aaa;
}

a:hover,
a:focus {
  text-decoration: none;
  color: white;
}

/* Miscellaneous */
.red {
  color: #960018;
}

.light-grey {
  color: #aaa;
}

.big-cont {
  height: 50vh;
}

.red-bg {
  background-color: #960018;
}

.dark-bg {
  background: rgba(10, 10, 10, 0.9);
}

.white-text {
  color: white;
}

.standard-pad {
  padding: 20px;
}

html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
  height: 100%;
}

.carousel-caption {
  top: 40%;
  bottom: auto;
}

/* Animations */
.animated {
  animation-duration: 1s;
  animation-fill-mode: both;
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    transform: none;
  }
}

.fadeInDown {
  animation-name: fadeInDown;
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  animation-name: fadeOutDown;
}

@keyframes slideInUp {
  from {
    /*transform: translate3d(0, 100%, 0);*/
    transform: translate(-50%, -20%);
    visibility: visible;
  }

  to {
    /*transform: translate3d(0, 0, 0);*/
    transform: translate(-50%, -50%);
  }
}

.slideInUp {
  animation-name: slideInUp;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  animation-name: fadeIn;
  animation-duration: 0.5s;
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.spin {
  animation-name: spin;
}
