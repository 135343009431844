.element.process {
  background-color: #fafafa;
}

.process-process {
  color: var(--secondary-color);
  font-family: 'Helvetica Neue Condensed';
  font-weight: 700;
  font-size: 40px;
  margin: 40px auto;
}

.process-step-icon {
  margin-bottom: 5px;
}

.process-extra-text {
  padding: 30px 30px 0;
  display: flex;
  justify-content: center;
  text-align: left;
  font-size: 18px;
  color: var(--secondary-light);
}

.step-title {
  display: block;
  width: 80%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.step-description {
  display: block;
  font-size: 15px;
  letter-spacing: 0.01em;
  margin: 0 15px;
}

.process-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 20px;
  margin-top: 50px;
  padding: 15px 32px;
  border-radius: 4px;
  cursor: pointer;
}

.process-button:hover {
  background-color: var(--primary-dark);
  color: #fff;
}

/* Table Row */
@media (min-width: 992px) {
  .table-row {
    border-top: 4px solid;
    border-bottom: 10px solid;
    border-radius: 13px;
  }

  .table-row > [class*='col-'] {
    float: none;
    padding: 10px 30px;
  }
  .table-row > [class*='col-']:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 4px;
  }
  .table-row > [class*='col-']:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 4px;
  }

  .table-row > [class*='col-'].odd {
    background-color: var(--accent-light);
    color: var(--secondary-color);
  }
  .table-row > [class*='col-'].even {
    background-color: var(--primary-color);
    color: var(--accent-light);
  }
}

/* Steps */
@media (max-width: 991px) {
  .table-row > [class*='col-']:first-child .process-steps {
    border-top: 4px solid var(--secondary-color);
    border-radius: 13px 13px 0 0;
  }
  .table-row > [class*='col-']:last-child .process-steps {
    border-bottom: 10px solid var(--secondary-color);
    border-radius: 0 0 13px 13px;
  }

  .process-steps {
    padding: 40px 20px;
  }

  .process-steps.odd {
    background-color: var(--accent-light);
    color: var(--secondary-color);
  }
  .process-steps.even {
    background-color: var(--primary-color);
    color: var(--accent-light);
  }
}

/* Step Arrow */
.step-triangle:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
}
@media (min-width: 992px) {
  .step-triangle:after {
    top: 48%;
    right: -11.5%;
    width: 32px;
    height: 32px;
    -moz-transform: rotate(-45deg) translateY(-50%);
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
  }
}
@media (min-width: 1200px) {
  .step-triangle:after {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 992px) {
  .step-triangle:after {
    bottom: -3%;
    left: 49.2%;
    width: 40px;
    height: 40px;
    -moz-transform: rotate(-45deg) translateY(-50%);
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateX(-50%);
  }
}
@media (max-width: 768px) {
  .step-triangle:after {
    bottom: -2%;
    left: 48.5%;
  }
}

.step-triangle.odd:after {
  background-color: var(--accent-light);
}
.step-triangle.even:after {
  background-color: var(--primary-color);
}

.table-row > [class*='col-']:last-child .step-triangle {
  display: none;
}
