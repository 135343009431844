.filepicker.dropzone .dz-preview.dz-image-preview {
    background: none;
    font-family: Poppins, sans-serif;
}

.filepicker.dropzone .dz-preview.dz-image-preview .dz-image {
    border-radius: 5px;
    font-family: Poppins, sans-serif;
}

.filepicker.dropzone .dz-clickable .dz-started {
    width: 100%;
    height: auto;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
}

.dz-custom {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: larger;
}

.data-dz-name .dz-filename .dz-message {
    color: #000000;
}
