.about-description {
  display: flex;
  justify-content: center;
  font-size: 30px;
  text-align: left;
}
@media (min-width: 992px) {
  .about-description {
    position: relative;
    height: 720px;
  }
}
@media (max-width: 992px) {
  .about-description {
    width: 90%;
    color: var(--secondary-light);
    font-size: 19px;
    margin: auto;
    margin-bottom: 50px;
  }
}

/* Scroll */
@media (min-width: 992px) {
  .about-description div {
    margin: 0;
    padding: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
    z-index: 0;
  }
}

#container {
  font-family: 'Berkshire Swash';
  width: 750px;
  height: 525px;
  top: 45px;
  -webkit-perspective: 5000px;
  perspective: 5000px;
  transform: rotateY(20deg);
}

#container p {
  padding: 30px 30px 0;
}

#container p:nth-last-child(2) {
  padding-bottom: 30px;
}

#container hr {
  margin: 0 20px 0 20px;
}

.panel-node {
  width: 100%;
}

.panel-cutout {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#content {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow-y: scroll;
  height: 100%;
}
#content::-webkit-scrollbar {
  display: none;
}

#content,
.panel-content {
  background: url('./images/scroll.webp');
}

.panel-content {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.backface > * {
  opacity: 0.25;
}

#lorem {
  font-size: 7em;
  color: var(--primary-color);
  float: left;
  line-height: 1;
  margin-right: 20px;
}

.scroll-title {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}
.scroll-title:first-child {
  margin-top: 30px;
}

.scroll-title.big {
  font-size: 45px;
}
.scroll-title.small {
  font-size: 25px;
}

.scroll-crest {
  float: right;
  height: 300px;
  margin: 20px 20px 0;
}
