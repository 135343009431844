@import url(https://fonts.googleapis.com/css?family=Berkshire+Swash);
@import url(https://fonts.googleapis.com/css?family=Montserrat:700);
@import url(https://fonts.googleapis.com/css?family=Roboto);
@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url(/static/media/HelveticaNeue-Condensed.3936a448.otf);
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Neue Condensed';
  src: url(/static/media/HelveticaNeue-CondensedBold.fb95cfea.otf);
  font-weight: bold;
  font-style: normal;
}

body {
  background-color: #fff;
  font-family: 'Helvetica Neue', sans-serif;
  margin: 0;
  padding: 0;
}

/* TODO - Top right corner quote button */
.quote-button {
  position: absolute;
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.quote-button:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .quote-button {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .quote-button {
    top: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

/* Not working after router change */
.App {
  text-align: center;
}

.App-logo {
  -webkit-animation: App-logo-spin infinite 20s linear;
          animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App-intro {
  font-size: large;
}

body {
  margin: 0;
  padding: 0;
  --primary-color: #960018;
  --primary-dark: #610301;
  --secondary-color: #444;
  --secondary-light: #555;
  --secondary-dark: #2e2f33;
  --secondary-dark-opaque: rgba(46, 47, 51, 0.9);
  --accent-color: #ccc;
  --accent-light: #eee;
}

@media (max-width: 410px) {
  h1 {
    font-size: 32px;
  }
}

#loading {
  display: block;
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
  background-color: #2e2f33;
  z-index: 1099;
}

#loading-image {
  position: absolute;
  background-image: url(/static/media/loading_gear.66b49201.png);
  background-size: cover;
  height: 200px;
  width: 200px;
  top: calc(50% - 100px);
  left: calc(50% - 100px);
  -webkit-animation: spin 5s linear infinite, fadeIn 0.5s ease-in;
  animation: spin 5s linear infinite, fadeIn 0.5s ease-in;
  z-index: 1100;
}
@media (max-width: 768px) {
  #loading-image {
    height: 150px;
    width: 150px;
    top: calc(50% - 75px);
    left: calc(50% - 75px);
  }
}

.picture {
  width: auto;
  height: auto;
}

.element {
  padding: 100px 0;
}

.title {
  width: 55%;
  font-weight: 700;
  font-size: 50px;
  color: var(--primary-color);
  margin: auto;
  margin-bottom: 0.5em;
  padding-bottom: 0.5em;
  border-bottom: 4px solid;
}
@media (max-width: 768px) {
  .title {
    width: 80%;
  }
}

.header {
  margin-top: 50px;
  padding-bottom: 10px;
  border-bottom: 1px solid #ccc;
}

@media (min-width: 992px) {
  .col-md-5th {
    width: 20%;
  }
}

.logo {
  height: 50px;
  width: 50px;
}
@media (max-width: 768px) {
  .logo {
    height: 40px;
    width: 40px;
  }
}

#title {
  margin: 0;
  line-height: 300%;
  background-color: black;
  color: white;
  border: none;
}

.padding-0 {
  padding-right: 0;
  padding-left: 0;
  margin: 0;
}

.max-size {
  width: 100%;
  height: 100%;
}

.bob {
  font-size: 130%;
  line-height: 300%;
  vertical-align: middle;
  display: inline-block;
}

#fam-pic {
  height: 80%;
  width: 100%;
}

/* Used for list in the footer TODO: assign names */
li {
  padding-bottom: 0px;
}

ul {
  list-style-type: none;
  list-style-position: inside;
  margin: 0;
  padding: 0;
}

a {
  text-decoration: none;
  color: #aaa;
}

a:hover,
a:focus {
  text-decoration: none;
  color: white;
}

/* Miscellaneous */
.red {
  color: #960018;
}

.light-grey {
  color: #aaa;
}

.big-cont {
  height: 50vh;
}

.red-bg {
  background-color: #960018;
}

.dark-bg {
  background: rgba(10, 10, 10, 0.9);
}

.white-text {
  color: white;
}

.standard-pad {
  padding: 20px;
}

html,
body,
.carousel,
.carousel-inner,
.carousel-inner .item {
  height: 100%;
}

.carousel-caption {
  top: 40%;
  bottom: auto;
}

/* Animations */
.animated {
  -webkit-animation-duration: 1s;
          animation-duration: 1s;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
}

@-webkit-keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes fadeInDown {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -100%, 0);
            transform: translate3d(0, -100%, 0);
  }

  to {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

.fadeInDown {
  -webkit-animation-name: fadeInDown;
          animation-name: fadeInDown;
}

@-webkit-keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

@keyframes fadeOutDown {
  from {
    opacity: 1;
  }

  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 100%, 0);
            transform: translate3d(0, 100%, 0);
  }
}

.fadeOutDown {
  -webkit-animation-name: fadeOutDown;
          animation-name: fadeOutDown;
}

@-webkit-keyframes slideInUp {
  from {
    /*transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    visibility: visible;
  }

  to {
    /*transform: translate3d(0, 0, 0);*/
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

@keyframes slideInUp {
  from {
    /*transform: translate3d(0, 100%, 0);*/
    -webkit-transform: translate(-50%, -20%);
            transform: translate(-50%, -20%);
    visibility: visible;
  }

  to {
    /*transform: translate3d(0, 0, 0);*/
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
  }
}

.slideInUp {
  -webkit-animation-name: slideInUp;
          animation-name: slideInUp;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fadeIn {
  -webkit-animation-name: fadeIn;
          animation-name: fadeIn;
  -webkit-animation-duration: 0.5s;
          animation-duration: 0.5s;
}

@-webkit-keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.spin {
  -webkit-animation-name: spin;
          animation-name: spin;
}

/* NavBar */
.navbar {
  background: transparent;
  font-family: 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  border: none;
  text-shadow: 2px 2px 2px #000;
  transition: background 0.5s ease;
  position: fixed;
  left: 0;
  right: 0;
}
@media(max-width: 575px) {
  .navbar {
    background-color: var(--secondary-dark) !important;
    opacity: 0.9;
  }
}

.nav-logo {
  cursor: pointer;
}

.nav-title {
  font-family: 'Helvetica Neue Condensed';
  font-size: 40px;
  text-transform: uppercase;
  color: #fff !important;
  cursor: pointer;
}
@media(max-width: 870px) {
  .nav-title {
    display: none;
  }
}

.nav-link {
  color: #fff !important;
  font-size: 25px;
  letter-spacing: 0.7px;
}

.gem {
  height: 50px;
  width: 50px;
  margin-left: 16px;
}

.nav-quote {
  border-style: solid;
  border-width: thin;
  background-color: rgba(9, 50, 195, 0.3);
  cursor: pointer;
  font-family: 'Helvetica Neue Condensed';
  font-size: 25px;
  letter-spacing: inherit;
}
.landing {
  position: relative;
  overflow: hidden;
  max-height: 60vh;
  max-width: 100vw;
}

.landing-image {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}

.landing-gradient {
  position: relative;
}
.landing-gradient:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%; /* FF3.6+ */ /* Chrome,Safari4+ */ /* Chrome10+,Safari5.1+ */ /* Opera 11.10+ */ /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* Rotating Text */
.word {
  position: absolute;
  display: -webkit-flex;
  display: flex;
  margin-left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  opacity: 0;
}

.letter {
  display: inline-block;
  position: relative;
  float: left;
  -webkit-transform: translateZ(25px);
          transform: translateZ(25px);
  -webkit-transform-origin: 50% 50% 25px;
          transform-origin: 50% 50% 25px;
  white-space: pre;
}
.letter.out {
  -webkit-transform: rotateX(90deg);
          transform: rotateX(90deg);
  transition: -webkit-transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.letter.behind {
  -webkit-transform: rotateX(-90deg);
          transform: rotateX(-90deg);
}
.letter.in {
  -webkit-transform: rotateX(0deg);
          transform: rotateX(0deg);
  transition: -webkit-transform 0.19s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.19s cubic-bezier(0.175, 0.885, 0.32, 1.275);
  transition: transform 0.19s cubic-bezier(0.175, 0.885, 0.32, 1.275), -webkit-transform 0.19s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Brothers Are */
.brothers-are {
  position: absolute;
  display: block;
  top: 100px;
  left: 0;
  right: 0;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #000;
  z-index: 10;
}

.brothers-are-text {
  font-family: 'Helvetica Neue Condensed';
  font-size: 60px;
  font-weight: 400;
  margin-bottom: -10px;
}
@media (max-width: 1400px) {
  .brothers-are-text {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .brothers-are-text {
    font-size: 37px;
  }
}

.brothers-are-word {
  font-family: 'Helvetica Neue Condensed';
  font-weight: 700;
  font-size: 80px;
  color: var(--primary-color);
}
@media (max-width: 1400px) {
  .brothers-are-word {
    font-size: 70px;
  }
}
@media (max-width: 768px) {
  .brothers-are-word {
    font-size: 47px;
  }
}

/* Learn More Button */
.learn-more {
  position: absolute;
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.learn-more:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .learn-more {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .learn-more {
    top: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

/* Quote */
.quote {
  position: absolute;
  height: 250px;
  width: 450px;
  color: #fff;
  right: 3%;
  bottom: 5%;
  opacity: 0;
  z-index: 1;
}
@media (max-width: 992px) {
  .quote {
    display: none;
  }
}

.quote p {
  font-size: 18px;
  font-weight: 300;
  margin-left: 1em;
  text-align: left;
}

.quote-text-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.quote-quotes {
  font-size: 55px;
  margin-top: -0.45em;
}

.quote-active-group {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  margin: 1em 2em 0;
}

.quote-active-labels {
  font-family: 'PT Sans Narrow';
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-around;
          justify-content: space-around;
  -webkit-align-items: flex-end;
          align-items: flex-end;
  text-align: right;
}

/* Quote Active Label */
.quote-active-labels h1 {
  margin: 0;
}

/* Quote Major and Class Labels */
.quote-active-labels h2 {
  font-style: italic;
  font-weight: 700;
  margin: 0;
}

.active-image {
  object-fit: cover;
  height: 120px;
  width: 120px;
}

/* Carousel Fading slide */
.carousel-fade .carousel-inner {
  background: #000;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .item {
  opacity: 0;
  transition-property: opacity;
}

.carousel-fade .next.left,
.carousel-fade .prev.right,
.carousel-fade .item.active {
  opacity: 1;
}

.carousel-fade .active.left,
.carousel-fade .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

/* Safari Fix */
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Carousel Control custom */
.carousel-control .control-icon {
  font-size: 48px;
  height: 30px;
  margin-top: -15px;
  width: 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 5;
}
.carousel-control .prev {
  margin-left: -15px;
  left: 50%;
} /* Prev */
.carousel-control .next {
  margin-right: -15px;
  right: 50%;
} /* Next */

/* Removing BS background */
.carousel .control-box {
  opacity: 0;
}
a.carousel-control.left {
  left: 0;
  background: none;
  border: 0;
}
a.carousel-control.right {
  right: 0;
  background: none;
  border: 0;
}

/* Animation */
.control-box,
a.carousel-control,
.carousel-indicators li {
  transition: all 250ms ease;
  /* hardware acceleration causes Bootstrap carousel controlbox margin error in webkit */
  /* Assigning animation to indicator li will make slides flicker */
}

/* Hover animation */
.carousel:hover .control-box {
  opacity: 1;
}
.carousel:hover a.carousel-control.left {
  left: 15px;
}
.carousel:hover a.carousel-control.right {
  right: 15px;
}

/* Carouse Indicator */
.carousel-indicators li.active,
.carousel-indicators li {
  border: 0;
}

.carousel-indicators li {
  background: #666;
  margin: 0 3px;
  width: 12px;
  height: 12px;
}

.carousel-indicators li.active {
  background: #fff;
  margin: 0 3px;
}

.landing .carousel-control.left,
.landing .carousel-control.right {
  display: none;
}

.element.process {
  background-color: #fafafa;
}

.process-process {
  color: var(--secondary-color);
  font-family: 'Helvetica Neue Condensed';
  font-weight: 700;
  font-size: 40px;
  margin: 40px auto;
}

.process-step-icon {
  margin-bottom: 5px;
}

.process-extra-text {
  padding: 30px 30px 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  text-align: left;
  font-size: 18px;
  color: var(--secondary-light);
}

.step-title {
  display: block;
  width: 80%;
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 20px;
  margin: auto;
  margin-bottom: 10px;
  padding-bottom: 5px;
  border-bottom: 2px solid;
  letter-spacing: 0.03em;
  text-transform: uppercase;
}

.step-description {
  display: block;
  font-size: 15px;
  letter-spacing: 0.01em;
  margin: 0 15px;
}

.process-button {
  display: inline-block;
  background-color: var(--primary-color);
  color: #fff;
  font-size: 20px;
  margin-top: 50px;
  padding: 15px 32px;
  border-radius: 4px;
  cursor: pointer;
}

.process-button:hover {
  background-color: var(--primary-dark);
  color: #fff;
}

/* Table Row */
@media (min-width: 992px) {
  .table-row {
    border-top: 4px solid;
    border-bottom: 10px solid;
    border-radius: 13px;
  }

  .table-row > [class*='col-'] {
    float: none;
    padding: 10px 30px;
  }
  .table-row > [class*='col-']:first-child {
    border-top-left-radius: 10px;
    border-bottom-left-radius: 4px;
  }
  .table-row > [class*='col-']:last-child {
    border-top-right-radius: 10px;
    border-bottom-right-radius: 4px;
  }

  .table-row > [class*='col-'].odd {
    background-color: var(--accent-light);
    color: var(--secondary-color);
  }
  .table-row > [class*='col-'].even {
    background-color: var(--primary-color);
    color: var(--accent-light);
  }
}

/* Steps */
@media (max-width: 991px) {
  .table-row > [class*='col-']:first-child .process-steps {
    border-top: 4px solid var(--secondary-color);
    border-radius: 13px 13px 0 0;
  }
  .table-row > [class*='col-']:last-child .process-steps {
    border-bottom: 10px solid var(--secondary-color);
    border-radius: 0 0 13px 13px;
  }

  .process-steps {
    padding: 40px 20px;
  }

  .process-steps.odd {
    background-color: var(--accent-light);
    color: var(--secondary-color);
  }
  .process-steps.even {
    background-color: var(--primary-color);
    color: var(--accent-light);
  }
}

/* Step Arrow */
.step-triangle:after {
  content: '';
  display: block;
  position: absolute;
  z-index: 1;
}
@media (min-width: 992px) {
  .step-triangle:after {
    top: 48%;
    right: -11.5%;
    width: 32px;
    height: 32px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateY(-50%);
  }
}
@media (min-width: 1200px) {
  .step-triangle:after {
    width: 40px;
    height: 40px;
  }
}
@media (max-width: 992px) {
  .step-triangle:after {
    bottom: -3%;
    left: 49.2%;
    width: 40px;
    height: 40px;
    -webkit-transform: rotate(-45deg) translateY(-50%);
    transform: rotate(-45deg) translateX(-50%);
  }
}
@media (max-width: 768px) {
  .step-triangle:after {
    bottom: -2%;
    left: 48.5%;
  }
}

.step-triangle.odd:after {
  background-color: var(--accent-light);
}
.step-triangle.even:after {
  background-color: var(--primary-color);
}

.table-row > [class*='col-']:last-child .step-triangle {
  display: none;
}

.about-description {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: 30px;
  text-align: left;
}
@media (min-width: 992px) {
  .about-description {
    position: relative;
    height: 720px;
  }
}
@media (max-width: 992px) {
  .about-description {
    width: 90%;
    color: var(--secondary-light);
    font-size: 19px;
    margin: auto;
    margin-bottom: 50px;
  }
}

/* Scroll */
@media (min-width: 992px) {
  .about-description div {
    margin: 0;
    padding: 0;
    -webkit-transform-style: preserve-3d;
    transform-style: preserve-3d;
    position: absolute;
    z-index: 0;
  }
}

#container {
  font-family: 'Berkshire Swash';
  width: 750px;
  height: 525px;
  top: 45px;
  -webkit-perspective: 5000px;
  perspective: 5000px;
  -webkit-transform: rotateY(20deg);
          transform: rotateY(20deg);
}

#container p {
  padding: 30px 30px 0;
}

#container p:nth-last-child(2) {
  padding-bottom: 30px;
}

#container hr {
  margin: 0 20px 0 20px;
}

.panel-node {
  width: 100%;
}

.panel-cutout {
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#content {
  -ms-overflow-style: none;
  overflow: -moz-scrollbars-none;
  overflow-y: scroll;
  height: 100%;
}
#content::-webkit-scrollbar {
  display: none;
}

#content,
.panel-content {
  background: url(/static/media/scroll.dd3fc0e3.webp);
}

.panel-content {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  user-select: none;
}

.backface > * {
  opacity: 0.25;
}

#lorem {
  font-size: 7em;
  color: var(--primary-color);
  float: left;
  line-height: 1;
  margin-right: 20px;
}

.scroll-title {
  margin-top: 10px;
  margin-bottom: 0;
  text-align: center;
}
.scroll-title:first-child {
  margin-top: 30px;
}

.scroll-title.big {
  font-size: 45px;
}
.scroll-title.small {
  font-size: 25px;
}

.scroll-crest {
  float: right;
  height: 300px;
  margin: 20px 20px 0;
}

.element.faq {
  background: url(/static/media/faq-background.c7a7209e.svg);
}

.widget {
  color: white;
  font-size: 12px;
}

/************** Modal ****************/
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 539px) {
  .modal-dialog {
    width: 93vw;
  }
}

/* Modal Header */
.modal-header {
  background: #9a0000; /* For browsers that do not support gradients */ /* For Safari 5.1 to 6.0 */ /* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #561e18, #670000, #800000, #9a0000);
  /* Standard syntax */
  color: #fff;
  padding: 25px 25px;
  border-radius: 3px 3px 0 0;
}
@media (max-width: 539px) {
  .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 130px;
    padding-top: 50px;
  }
}
.modal-title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 539px) {
  .modal-title {
    font-size: 25px;
  }
}

/* Modal Image */
.modal-image-mobile {
  display: none;
}
@media (max-width: 539px) {
  .modal-image-mobile {
    display: block;
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -40px;
    border: 7px solid #fff;
    border-radius: 50%;
  }
}
.modal-image {
  display: inline-block;
  object-fit: cover;
  vertical-align: baseline;
  width: 50%;
  height: 400px;
  margin-left: 10px;
  border-radius: 6px;
}
@media (max-width: 539px) {
  .modal-image {
    display: none;
  }
}

/* Modal Body */
.modal-body {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  margin: 20px auto;
}
@media (max-width: 539px) {
  .modal-body {
    font-size: 18px;
    margin: 50px 0 0;
    padding: 15px 0 0;
  }
}

.contact-form {
  max-width: 360px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 16px;
}

.contact-form > :not(:last-child) {
  margin-bottom: 16px;
}

.contact-form .form-field {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-form .label-content {
  margin-bottom: 4px;
}

.contact-form input, .contact-form textarea {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  max-width: 100%;
}

.contact-form label {
  text-align: left;
}

/* Learn More Button */
.learn-more {
  position: absolute;
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.learn-more:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .learn-more {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .learn-more {
    top: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

/************** Modal ****************/
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 539px) {
  .modal-dialog {
    width: 93vw;
  }
}

/* Modal Header */
.modal-header {
  background: #9a0000; /* For browsers that do not support gradients */ /* For Safari 5.1 to 6.0 */ /* For Opera 11.1 to 12.0 */ /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #561e18, #670000, #800000, #9a0000);
  /* Standard syntax */
  color: #fff;
  padding: 25px 25px;
  border-radius: 3px 3px 0 0;
}
@media (max-width: 539px) {
  .modal-header {
    display: -webkit-flex;
    display: flex;
    -webkit-flex-direction: column;
            flex-direction: column;
    -webkit-justify-content: center;
            justify-content: center;
    height: 130px;
    padding-top: 50px;
  }
}
.modal-title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 539px) {
  .modal-title {
    font-size: 25px;
  }
}

/* Modal Image */
.modal-image-mobile {
  display: none;
}
@media (max-width: 539px) {
  .modal-image-mobile {
    display: block;
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -40px;
    border: 7px solid #fff;
    border-radius: 50%;
  }
}
.modal-image {
  display: inline-block;
  object-fit: cover;
  vertical-align: baseline;
  width: 50%;
  height: 400px;
  margin-left: 10px;
  border-radius: 6px;
}
@media (max-width: 539px) {
  .modal-image {
    display: none;
  }
}

/* Modal Body */
.modal-body {
  display: -webkit-flex;
  display: flex;
  font-family: 'Roboto', sans-serif;
  margin: 20px auto;
}
@media (max-width: 539px) {
  .modal-body {
    font-size: 18px;
    margin: 50px 0 0;
    padding: 15px 0 0;
  }
}

.contact-form {
  max-width: 360px;
  margin: 0 auto;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  padding: 16px;
}

.contact-form > :not(:last-child) {
  margin-bottom: 16px;
}

.contact-form .form-field {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.contact-form .label-content {
  margin-bottom: 4px;
}

.contact-form input, .contact-form textarea {
  display: -webkit-flex;
  display: flex;
  -webkit-flex: 1 1;
          flex: 1 1;
  width: 100%;
  max-width: 100%;
}

.contact-form label {
  text-align: left;
}

/* Send Button */
.send-button {
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.send-button:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .send-button {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .send-button {
    top: 250px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
  }
}

.filepicker.dropzone .dz-preview.dz-image-preview {
    background: none;
    font-family: Poppins, sans-serif;
}

.filepicker.dropzone .dz-preview.dz-image-preview .dz-image {
    border-radius: 5px;
    font-family: Poppins, sans-serif;
}

.filepicker.dropzone .dz-clickable .dz-started {
    width: 100%;
    height: auto;
    border-color: rgb(102, 102, 102);
    border-style: dashed;
}

.dz-custom {
    color: #000000;
    font-family: "Poppins", sans-serif;
    font-weight: bold;
    font-size: larger;
}

.data-dz-name .dz-filename .dz-message {
    color: #000000;
}

