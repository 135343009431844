/* NavBar */
.navbar {
  background: transparent;
  font-family: 'Helvetica Neue', 'HelveticaNeue', Helvetica, Arial, sans-serif;
  border: none;
  text-shadow: 2px 2px 2px #000;
  -webkit-transition: background 0.5s ease;
  -moz-transition: background 0.5s ease;
  -o-transition: background 0.5s ease;
  transition: background 0.5s ease;
  position: fixed;
  left: 0;
  right: 0;
}
@media(max-width: 575px) {
  .navbar {
    background-color: var(--secondary-dark) !important;
    opacity: 0.9;
  }
}

.nav-logo {
  cursor: pointer;
}

.nav-title {
  font-family: 'Helvetica Neue Condensed';
  font-size: 40px;
  text-transform: uppercase;
  color: #fff !important;
  cursor: pointer;
}
@media(max-width: 870px) {
  .nav-title {
    display: none;
  }
}

.nav-link {
  color: #fff !important;
  font-size: 25px;
  letter-spacing: 0.7px;
}

.gem {
  height: 50px;
  width: 50px;
  margin-left: 16px;
}

.nav-quote {
  border-style: solid;
  border-width: thin;
  background-color: rgba(9, 50, 195, 0.3);
  cursor: pointer;
  font-family: 'Helvetica Neue Condensed';
  font-size: 25px;
  letter-spacing: inherit;
}