.landing {
  position: relative;
  overflow: hidden;
  max-height: 60vh;
  max-width: 100vw;
}

.landing-image {
  object-fit: cover;
  height: 100vh;
  width: 100vw;
}

.landing-gradient {
  position: relative;
}
.landing-gradient:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* FF3.6+ */
  background: -webkit-gradient(
    linear,
    left top,
    left bottom,
    color-stop(0%, rgba(0, 0, 0, 0.6)),
    color-stop(100%, rgba(0, 0, 0, 0))
  ); /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0.6) 0%, rgba(0, 0, 0, 0.6) 100%); /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a6000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
}

/* Rotating Text */
.word {
  position: absolute;
  display: flex;
  margin-left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}

.letter {
  display: inline-block;
  position: relative;
  float: left;
  transform: translateZ(25px);
  transform-origin: 50% 50% 25px;
  white-space: pre;
}
.letter.out {
  transform: rotateX(90deg);
  -webkit-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.letter.behind {
  transform: rotateX(-90deg);
}
.letter.in {
  transform: rotateX(0deg);
  -webkit-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -moz-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  -o-transition: transform 0.16s cubic-bezier(0.55, 0.055, 0.675, 0.19);
  transition: transform 0.19s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

/* Brothers Are */
.brothers-are {
  position: absolute;
  display: block;
  top: 100px;
  left: 0;
  right: 0;
  color: #fff;
  text-transform: uppercase;
  text-shadow: 2px 2px 2px #000;
  z-index: 10;
}

.brothers-are-text {
  font-family: 'Helvetica Neue Condensed';
  font-size: 60px;
  font-weight: 400;
  margin-bottom: -10px;
}
@media (max-width: 1400px) {
  .brothers-are-text {
    font-size: 50px;
  }
}
@media (max-width: 768px) {
  .brothers-are-text {
    font-size: 37px;
  }
}

.brothers-are-word {
  font-family: 'Helvetica Neue Condensed';
  font-weight: 700;
  font-size: 80px;
  color: var(--primary-color);
}
@media (max-width: 1400px) {
  .brothers-are-word {
    font-size: 70px;
  }
}
@media (max-width: 768px) {
  .brothers-are-word {
    font-size: 47px;
  }
}

/* Learn More Button */
.learn-more {
  position: absolute;
  top: 320px;
  left: 50%;
  background-color: #777;
  color: #fff;
  border-radius: 4px;
  font-size: 20px;
  font-weight: 900;
  padding: 10px 20px;
  opacity: 0.8;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 3px 10px, rgba(0, 0, 0, 0.63) 0px 3px 10px;
  transform: translateX(-50%);
  cursor: pointer;
  white-space: nowrap;
  z-index: 1;
}
.learn-more:hover {
  background-color: #666;
  color: #fff;
}
@media (max-width: 1400px) {
  .learn-more {
    top: 290px;
  }
}
@media (max-width: 768px) {
  .learn-more {
    top: 250px;
    left: 50%;
    transform: translateX(-50%);
  }
}

/* Quote */
.quote {
  position: absolute;
  height: 250px;
  width: 450px;
  color: #fff;
  right: 3%;
  bottom: 5%;
  opacity: 0;
  z-index: 1;
}
@media (max-width: 992px) {
  .quote {
    display: none;
  }
}

.quote p {
  font-size: 18px;
  font-weight: 300;
  margin-left: 1em;
  text-align: left;
}

.quote-text-group {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.quote-quotes {
  font-size: 55px;
  margin-top: -0.45em;
}

.quote-active-group {
  display: flex;
  justify-content: space-around;
  margin: 1em 2em 0;
}

.quote-active-labels {
  font-family: 'PT Sans Narrow';
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-end;
  text-align: right;
}

/* Quote Active Label */
.quote-active-labels h1 {
  margin: 0;
}

/* Quote Major and Class Labels */
.quote-active-labels h2 {
  font-style: italic;
  font-weight: 700;
  margin: 0;
}

.active-image {
  object-fit: cover;
  height: 120px;
  width: 120px;
}

/* Carousel Fading slide */
.carousel-fade .carousel-inner {
  background: #000;
}
.carousel-fade .carousel-control {
  z-index: 2;
}
.carousel-fade .item {
  opacity: 0;
  -webkit-transition-property: opacity;
  -moz-transition-property: opacity;
  -o-transition-property: opacity;
  transition-property: opacity;
}

.carousel-fade .next.left,
.carousel-fade .prev.right,
.carousel-fade .item.active {
  opacity: 1;
}

.carousel-fade .active.left,
.carousel-fade .active.right {
  left: 0;
  opacity: 0;
  z-index: 1;
}

/* Safari Fix */
@media all and (transform-3d), (-webkit-transform-3d) {
  .carousel-fade .carousel-inner > .item.next,
  .carousel-fade .carousel-inner > .item.active.right {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.prev,
  .carousel-fade .carousel-inner > .item.active.left {
    opacity: 0;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
  .carousel-fade .carousel-inner > .item.next.left,
  .carousel-fade .carousel-inner > .item.prev.right,
  .carousel-fade .carousel-inner > .item.active {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}

/* Carousel Control custom */
.carousel-control .control-icon {
  font-size: 48px;
  height: 30px;
  margin-top: -15px;
  width: 30px;
  display: inline-block;
  position: absolute;
  top: 50%;
  z-index: 5;
}
.carousel-control .prev {
  margin-left: -15px;
  left: 50%;
} /* Prev */
.carousel-control .next {
  margin-right: -15px;
  right: 50%;
} /* Next */

/* Removing BS background */
.carousel .control-box {
  opacity: 0;
}
a.carousel-control.left {
  left: 0;
  background: none;
  border: 0;
}
a.carousel-control.right {
  right: 0;
  background: none;
  border: 0;
}

/* Animation */
.control-box,
a.carousel-control,
.carousel-indicators li {
  -webkit-transition: all 250ms ease;
  -moz-transition: all 250ms ease;
  -ms-transition: all 250ms ease;
  -o-transition: all 250ms ease;
  transition: all 250ms ease;
  /* hardware acceleration causes Bootstrap carousel controlbox margin error in webkit */
  /* Assigning animation to indicator li will make slides flicker */
}

/* Hover animation */
.carousel:hover .control-box {
  opacity: 1;
}
.carousel:hover a.carousel-control.left {
  left: 15px;
}
.carousel:hover a.carousel-control.right {
  right: 15px;
}

/* Carouse Indicator */
.carousel-indicators li.active,
.carousel-indicators li {
  border: 0;
}

.carousel-indicators li {
  background: #666;
  margin: 0 3px;
  width: 12px;
  height: 12px;
}

.carousel-indicators li.active {
  background: #fff;
  margin: 0 3px;
}

.landing .carousel-control.left,
.landing .carousel-control.right {
  display: none;
}
