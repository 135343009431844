/************** Modal ****************/
.modal {
  text-align: center;
  padding: 0 !important;
}

.modal:before {
  content: '';
  display: inline-block;
  height: 100%;
  vertical-align: middle;
}

.modal-dialog {
  display: inline-block;
  text-align: left;
  vertical-align: middle;
}
@media (max-width: 539px) {
  .modal-dialog {
    width: 93vw;
  }
}

/* Modal Header */
.modal-header {
  background: #9a0000; /* For browsers that do not support gradients */
  background: -webkit-linear-gradient(left top, #9a0000, #800000, #670000, #561e18); /* For Safari 5.1 to 6.0 */
  background: -o-linear-gradient(bottom right, #9a0000, #800000, #670000, #561e18); /* For Opera 11.1 to 12.0 */
  background: -moz-linear-gradient(bottom right, #9a0000, #800000, #670000, #561e18); /* For Firefox 3.6 to 15 */
  background: linear-gradient(to bottom right, #561e18, #670000, #800000, #9a0000);
  /* Standard syntax */
  color: #fff;
  padding: 25px 25px;
  border-radius: 3px 3px 0 0;
}
@media (max-width: 539px) {
  .modal-header {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 130px;
    padding-top: 50px;
  }
}
.modal-title {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 40px;
  text-align: center;
}
@media (max-width: 539px) {
  .modal-title {
    font-size: 25px;
  }
}

/* Modal Image */
.modal-image-mobile {
  display: none;
}
@media (max-width: 539px) {
  .modal-image-mobile {
    display: block;
    object-fit: cover;
    width: 120px;
    height: 120px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: -40px;
    border: 7px solid #fff;
    border-radius: 50%;
  }
}
.modal-image {
  display: inline-block;
  object-fit: cover;
  vertical-align: baseline;
  width: 50%;
  height: 400px;
  margin-left: 10px;
  border-radius: 6px;
}
@media (max-width: 539px) {
  .modal-image {
    display: none;
  }
}

/* Modal Body */
.modal-body {
  display: flex;
  font-family: 'Roboto', sans-serif;
  margin: 20px auto;
}
@media (max-width: 539px) {
  .modal-body {
    font-size: 18px;
    margin: 50px 0 0;
    padding: 15px 0 0;
  }
}
